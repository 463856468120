<template>
    <DadosPessoais :matricula="currentUser.matricula"/>
</template>

<script lang="js">
    
    import DadosPessoais from '../../components/consulta/DadosPessoais.vue'

    export  default {
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},

        components: { DadosPessoais }
    }

</script>