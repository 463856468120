<template>
    <div class="col-12 p-lg-4">
        <div class="card height-100 flex flex-column align-items-center">
            <div class="pl-0 col-12">
                <Button type="button" icon="pi pi-pencil" label="Alterar" class="p-button p-component p-button-info mb-2"
                    :disabled="alterarHabilitado" @click="habilitarAlteracao()" />
            </div>
            
            <img class="mt-3" :src="imagemFotoPerfil" style="width:80px" alt="profile">

            <p>Personalize sua conta com uma foto. Sua foto de perfil aparecerá em aplicativos e dispositivos que usam sua conta</p>
            <button type="buttom" label="Alterar Foto" @click="habilitarDialogFotoPerfil">Alterar Foto</button>
            


            <h5 class="mb-1">{{ cooperadoDadoPessoal.nome }}</h5>

            <div class="col-12">
                <div class="flex flex-column align-items-left">
                    <hr />
                </div>
            </div>
            <div class="col-12">
                <div class="location flex flex-column align-items-left">
                    <label for="cep" class="block text-500 font-medium mb-2">
                        CEP
                        <span style="color: red;" v-if="alterarHabilitado">*</span>
                    </label>
                    <span v-if="!alterarHabilitado" class="font-bold text-900">
                        {{ cooperadoDadoPessoal.cep }}
                    </span>
                    <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1', { 'p-invalid': cepInvalido }]" id="cep"
                        v-model="cooperadoDadoPessoal.cep" @blur="consultarViaCEP()" />
                </div>
                <div class="flex flex-column align-items-left mt-3">
                    <label for="endereco" class="block text-500 font-medium mb-2">
                        Endereço
                        <span style="color: red;" v-if="alterarHabilitado">*</span>
                    </label>
                    <span v-if="!alterarHabilitado" class="font-bold text-900">{{ cooperadoDadoPessoal.endereco }}</span>
                    <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1', { 'p-invalid': enderecoInvalido }]"
                        id="endereco" v-model="cooperadoDadoPessoal.endereco" />
                </div>
                <div v-if="cooperadoDadoPessoal.complemento" class="flex flex-column align-items-left mt-3">
                    <label for="complemento" class="block text-500 font-medium mb-2">Complemento</label>
                    <span v-if="!alterarHabilitado" class="font-bold text-900">{{ cooperadoDadoPessoal.complemento }}</span>
                    <InputText v-if="alterarHabilitado" class="w-full mt-1" id="complemento"
                        v-model="cooperadoDadoPessoal.complemento" />
                </div>
                <div class="flex flex-column align-items-left mt-3">
                    <label for="bairro" class="block text-500 font-medium mb-2">
                        Bairro
                        <span style="color: red;" v-if="alterarHabilitado">*</span>
                    </label>
                    <span v-if="!alterarHabilitado" class="font-bold text-900">{{ cooperadoDadoPessoal.bairro }}</span>
                    <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1', { 'p-invalid': bairroInvalido }]"
                        id="bairro" v-model="cooperadoDadoPessoal.bairro" />
                </div>
                <div class="flex flex-column md:flex-row">
                    <div class="pl-0 md:col-8 mt-3">
                        <label for="cidade" class="block text-500 font-medium mb-2">
                            Cidade
                            <span style="color: red;" v-if="alterarHabilitado">*</span>
                        </label>
                        <span v-if="!alterarHabilitado" class="font-bold text-900">{{ cooperadoDadoPessoal.cidade }}</span>
                        <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1', { 'p-invalid': cidadeInvalido }]"
                            id="cidade" v-model="cooperadoDadoPessoal.cidade" />
                    </div>
                    <div class="pl-0 md:pr-0 col-4 mt-3">
                        <label for="estado" class="block text-500 font-medium mb-2">
                            Estado
                            <span style="color: red;" v-if="alterarHabilitado">*</span>
                        </label>
                        <span v-if="!alterarHabilitado" class="font-bold text-900">{{ cooperadoDadoPessoal.estado }}</span>
                        <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1', { 'p-invalid': estadoInvalido }]"
                            id="estado" v-model="cooperadoDadoPessoal.estado" />
                    </div>
                </div>
                <div class="flex flex-column md:flex-row">
                    <div class="pl-0 col-12 md:col-6 mt-3">
                        <label for="telefone" class="block text-500 font-medium mb-2">Telefone</label>
                        <span v-if="!alterarHabilitado" class="font-bold text-900">{{ cooperadoDadoPessoal.telefone }}</span>
                        <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1']" id="telefone"
                            v-model="cooperadoDadoPessoal.telefone" />
                    </div>
                    <div class="pl-0 md:pr-0 col-12 md:col-6 mt-3">
                        <label for="celular" class="block text-500 font-medium mb-2">Celular</label>
                        <span v-if="!alterarHabilitado" class="font-bold text-900">{{ cooperadoDadoPessoal.celular }}</span>
                        <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1']" id="celular"
                            v-model="cooperadoDadoPessoal.celular" />
                    </div>
                </div>
                <div class="flex flex-column align-items-left mt-3">
                    <label for="email" class="block text-500 font-medium mb-2">
                        Email
                        <span style="color: red;" v-if="alterarHabilitado">*</span>
                    </label>
                    <span v-if="!alterarHabilitado" class="font-bold text-900">{{ cooperadoDadoPessoal.email }}</span>
                    <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1', { 'p-invalid': emailInvalido }]" id="email"
                        v-model="cooperadoDadoPessoal.email" />
                </div>
                <div class="flex flex-row">
                    <div class="pl-0 col-6 mt-3">
                        <label for="areaPropria" class="block text-500 font-medium mb-2">Área Própria</label>
                        <span v-if="!alterarHabilitado" class="font-bold text-900"><span>
                                {{ cooperadoDadoPessoal.areaPropria }}</span></span>
                        <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1']" id="areaPropria"
                            v-model="cooperadoDadoPessoal.areaPropria" />
                    </div>
                    <div class="pr-0 col-6 mt-3">
                        <label for="areaArrendada" class="block text-500 font-medium mb-2">Área Arrendada</label>
                        <span v-if="!alterarHabilitado"
                            class="font-bold text-900"><span>{{ cooperadoDadoPessoal.areaArrendada }}</span></span>
                        <InputText v-if="alterarHabilitado" :class="['w-full', 'mt-1']" id="areaArrendada"
                            v-model="cooperadoDadoPessoal.areaArrendada" />
                    </div>
                </div>
                <div class="mt-3">
                    <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger"
                        v-if="alterarHabilitado" @click="confirmarCancelamento()" />
                    <Button type="button" icon="pi pi-save" label="Salvar"
                        class="p-button p-component p-button-success ml-2" v-if="alterarHabilitado" @click="salvar()" />
                </div>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="dialog" :style="{ width: '300px' }" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Tem certeza de que deseja cancelar?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="cancelar()" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="prepararTela()" />
        </template>
    </Dialog>

    <Dialog v-model:visible="dialogFotoPerfil" :style="{ width: '50%' }" header="Alterar Foto" :modal="true">
        <div>
            
            <div class="content">
                <section class="cropper-area">
                    <div class="img-cropper">
                        <vue-cropper ref="cropper" :src="imgSrc" :aspectRatio="1/1" :initialAspectRatio="1/1" />
                    </div>
                </section>
                <section class="preview-area" v-if="false">
                    <p>Preview</p>
                    <div class="preview" />
                    <p>Cropped Image</p>
                    <div class="cropped-image">
                        <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
                        <div v-else class="crop-placeholder" />
                    </div>
                </section>

            </div>
            <div class="mt-3">
                <input ref="input" type="file" name="image" accept="image/*" @change="setImage" />
            </div>
            

            

        </div>

        <template #footer>
            <Button label="Salvar"   icon="pi pi-save" class="p-button-text p-button" @click.prevent="salvarImagemCortada" />
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text p-button-danger" @click="cancelarAlterarFoto()" />
            
        </template>

    </Dialog>

    <Toast position="top-right" group="bc">
        <template #message="slotProps">
            <span :class="iconClass"></span>
            <div class="p-toast-message-text">
                <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
                <div class="p-toast-detail" v-html="slotProps.message.detail" />
            </div>
        </template>
    </Toast>
</template>


<script>
import CooperadoService from "../../service/CooperadoService";
import ViaCEPService from "../../service/ViaCEPService";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";


export default {
    name: 'DadosPessoais',

    props: {
        matricula: {
            type: Number,
            required: true
        }
    },
    components: {
        VueCropper,
    },
    data() {
        return {
            cooperadoDadoPessoal: {},
            cepErpCache: null,
            alterarHabilitado: false,
            dialog: false,

            enderecoInvalido: false,
            bairroInvalido: false,
            cidadeInvalido: false,
            estadoInvalido: false,
            cepInvalido: false,
            emailInvalido: false,
            imagemFotoPerfil: null,

            imgSrc: null,
            cropImg: '',
            data: null,
            dialogFotoPerfil: false,
            dtoImagem: {},
        }
    },
    created() {
        this.getDadosPessoais();

    },
    mounted() {
        this.mostrarFotoPerfil();
    },
    methods: {
        getDadosPessoais() {
            CooperadoService.buscarDadoPessoal(this.$props.matricula)
                .then((response) => {
                    this.cooperadoDadoPessoal = response.data;
                    this.cepErpCache = response.data.cep;
                });
        },

        salvar() {

            if(this.cropImg != null){

                this.dtoImagem.matricula = this.cooperadoDadoPessoal.matricula;

                let fotoCooperado = { "base64": this.cropImg };

                this.dtoImagem.fotoCooperado = fotoCooperado;

                CooperadoService.salvarFotoPerfil(this.dtoImagem);
            }


            if (!this.validarCampos()) return;

            CooperadoService.alterarDadoPessoal(this.cooperadoDadoPessoal)
                .then(response => {
                    this.abrirToast('success', 'Sucesso', 'Dados alterado com sucesso!', 3000);

                    setTimeout(() => {
                        this.prepararTela();
                    }, "1000");
                })
                .catch(error => {
                    let campos = error.response.data.message_error.split("\n");
                    let mensagem = '';
                    campos.forEach(e => {
                        mensagem += e + '<br>'

                    });
                    this.showToastCustom('error', 'Falha', mensagem, 3000, 'bc');
                });

                setTimeout(() => {
                    this.$router.go(this.$router.currentRoute);
                }, "6000");    
            

        },

        consultarViaCEP() {
            if (this.cepErpCache != this.cooperadoDadoPessoal.cep) {
                ViaCEPService.consultarViaCEP(this.cooperadoDadoPessoal.cep)
                    .then(({ data }) => {
                        if (!data.erro) {
                            this.cooperadoDadoPessoal.endereco = data.logradouro;
                            this.cooperadoDadoPessoal.bairro = data.bairro;
                            this.cooperadoDadoPessoal.cidade = data.localidade;
                            this.cooperadoDadoPessoal.estado = data.uf;
                            this.cepErpCache = this.cooperadoDadoPessoal.cep;
                        } else {
                            this.abrirToast('error', 'Falha', `Endereço não encontrado para o CEP ${this.cepErp}`, 3000);
                            this.cooperadoDadoPessoal.cep = this.cepErpCache;
                        }
                    });
            }
        },

        formatDate(date) {
            const options = {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZone: 'America/Sao_Paulo',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }
            return new Date(date).toLocaleDateString("pt-BR", options)
        },
        formatCurrency(value) {
            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        formatCep(value) {
            if (value) {
                return value.replace(/\D/g, '')
                    .replace(/(\d{5})(\d)/, '$1-$2')
                    .replace(/(-\d{3})\d+?$/, '$1');
            }
        },
        habilitarAlteracao() {
            this.alterarHabilitado = !this.alterarHabilitado;
        },
        confirmarCancelamento() {
            this.dialog = true;
        },
        cancelar() {
            this.dialog = false;
        },
        prepararTela() {
            this.cancelar();
            this.getDadosPessoais();
            this.habilitarAlteracao();
        },
        abrirToast(tipo, titulo, descricao, tempo) {
            this.$toast.add({
                severity: tipo,
                summary: titulo,
                detail: descricao,
                life: tempo
            });
        },
        validarCampos() {
            let isValid = true;

            if (!this.cooperadoDadoPessoal.endereco) {
                this.enderecoInvalido = true;
                isValid = false;
            }

            if (!this.cooperadoDadoPessoal.bairro) {
                this.bairroInvalido = true;
                isValid = false;
            }

            if (!this.cooperadoDadoPessoal.cidade) {
                this.cidadeInvalido = true;
                isValid = false;
            }

            if (!this.cooperadoDadoPessoal.estado) {
                this.estadoInvalido = true;
                isValid = false;
            }

            if (!this.cooperadoDadoPessoal.cep) {
                this.cepInvalido = true;
                isValid = false;
            }

            if (!this.cooperadoDadoPessoal.email) {
                this.emailInvalido = true;
                isValid = false;
            }

            if (!isValid) {
                this.abrirToast('error', 'Falha', 'Preencher os campos obrigatórios.', 3000);
                return;
            }

            return isValid;
        },

        showToastCustom(severityType, summaryType, detailType, lifeType, groupType) {
            this.$toast.add({
                severity: severityType,
                summary: summaryType,
                detail: detailType,
                life: lifeType,
                group: groupType
            })
        },
        mostrarFotoPerfil() {

            console.log("currentUser", this.$props.matricula);

            let matricula = this.$props.matricula;

            CooperadoService.imagemFotoPerfil(matricula)
                .then(response => {
                    this.imagemFotoPerfil = response.data;
                    this.imgSrc = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        habilitarDialogFotoPerfil(){
            this.alterarHabilitado = true;
            this.dialogFotoPerfil = true;
        },

        cancelarAlterarFoto(){
            this.dialogFotoPerfil = false;
            this.cropImg = '';

        },

        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },

        setData() {
            if (!this.data) return;

            this.$refs.cropper.setData(JSON.parse(this.data));
        },
        
        setImage(e) {
            const file = e.target.files[0];

            if (file.type.indexOf('image/') === -1) {
                alert('Please select an image file');
                return;
            }

            if (typeof FileReader === 'function') {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.imgSrc = event.target.result;
                    // rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result);
                };

                reader.readAsDataURL(file);
            } else {
                alert('Sorry, FileReader API not supported');
            }
        },
        showFileChooser() {
            this.$refs.input.click();
        },
        
        excluirFoto(){
            this.imagemFotoPerfil = null;
        },

        salvarImagemCortada(){
            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.imagemFotoPerfil = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.dialogFotoPerfil = false;
        },


    }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

@media screen and (min-width: 1500px) {
    .p-lg-1 {
        width: 8.3333%;
    }

    .p-lg-2 {
        width: 16.6667%;
    }

    .p-lg-3 {
        width: 25%;
    }

    .p-lg-4 {
        width: 33.3333%;
    }

    .p-lg-5 {
        width: 41.6667%;
    }

    .p-lg-6 {
        width: 50%;
    }

    .p-lg-7 {
        width: 58.3333%;
    }

    .p-lg-8 {
        width: 66.6667%;
    }

    .p-lg-9 {
        width: 75%;
    }

    .p-lg-10 {
        width: 83.3333%;
    }

    .p-lg-11 {
        width: 91.6667%;
    }

    .p-lg-12 {
        width: 100%;
    }
}
</style>
